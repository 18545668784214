import { MyRoutes } from "@/routes/Routes";
import { Suspense, useEffect, useState } from "react";
import { Preloader } from "@/components/preloader/Preloader";
import { useTranslation } from 'react-i18next';

function App() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.title = t("meta-title");

    const metaDescription = document.querySelector('meta[name="description"]');
    const metaOgDescription = document.querySelector(
      'meta[property="og:description"]'
    );
    const metaOgTitle = document.querySelector('meta[property="og:title"]');
    const metaTwitterTitle = document.querySelector(
      'meta[property="twitter:title"]'
    );
    const metaTwitterImgAlt = document.querySelector(
      'meta[name="twitter:image:alt"]'
    );
    const metaTwitterDescription = document.querySelector(
      'meta[name="twitter:description"]'
    );

    metaDescription &&
      metaDescription.setAttribute("content", t("meta-description_short"));
    metaOgDescription &&
      metaOgDescription.setAttribute("content", t("meta-description"));
    metaOgTitle && metaOgTitle.setAttribute("content", t("meta-title"));
    metaTwitterTitle &&
      metaTwitterTitle.setAttribute("content", t("meta-title"));
    metaTwitterImgAlt &&
      metaTwitterImgAlt.setAttribute("content", t("meta-description"));
    metaTwitterDescription &&
      metaTwitterDescription.setAttribute("content", t("meta-description"));
  }, [i18n, t]);

  useEffect(() => {
    const isFirstVisit = !localStorage.getItem("visited");
    if (isFirstVisit) {
      localStorage.setItem("visited", "true");
      const browserLang = navigator.language.split('-')[0]; // exp: "en" ou "fr"
      const lng = browserLang === "fr" ? "fr" : "en";
      localStorage.setItem("lng", lng);
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (isFirstVisit) {
      setLoading(true);
    }
  }, []);

  const handlePreloaderComplete = () => {
    setLoading(false); // Met à jour l'état de chargement lorsque le preloader est complet
  };

  return (
    <div>
      {loading ? (
        <Preloader onCompleteAnimation={handlePreloaderComplete} />
      ) : (
        <Suspense fallback={" "}>
          <MyRoutes />
        </Suspense>
      )}  
    </div>
  );
}

export default App;
