import useBreakpoint from "@/utils/breakPoints";
import { motion, useAnimate, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";

export const Preloader = ({ onCompleteAnimation }: { onCompleteAnimation: () => void }) => {
  const [count, setCount] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const [loaderDone, setLoaderDone] = useState(false);

  const [scope, animate] = useAnimate();
  const controls = useAnimation();

  const breakpoint = useBreakpoint();


  const variantsCount = {
    normal: { x: 0 },
    hidden: {
      x: "100vw",
      transition: { duration: 1.5, ease: [0.76, 0, 0.24, 1] },
    },
  };

  useEffect(() => {
    if (loaderDone) {
      const sequence = async () => {
        await animate(
          scope.current,
          { 
            width:  breakpoint === "small" ? "68px" :
            breakpoint === "mini" ? "62px" :
            breakpoint === "table" ? "85px" :
            "110px"
          },
          {
            delay: 0.3,
            duration: 0.75,
            ease: [0.76, 0, 0.24, 1],
          }
        );
        await animate(
          scope.current,
          { top: "100vh", rotate: -15 },
          { duration: 1, ease: "circIn", onComplete: onCompleteAnimation }
        );
      };

      sequence();
    }
  }, [scope, animate, loaderDone, onCompleteAnimation, breakpoint]);

  useEffect(() => {
    // Commencez l'animation de 0 à 100
    controls.start({
      width: `${count}%`,
      transition: { duration: 3, ease: "linear" },
    });
  }, [controls, count]);

  useEffect(() => {
    if (count >= 100) {
      setLoaderDone(true);
    }
  }, [count]);

  useEffect(() => {
    if (count < 100) {
      const randomDelay = Math.random() * 300; // Délai aléatoire entre 0 et 300ms
      const increment = Math.random() * 10; // Incrément aléatoire entre 0 et 10

      setTimeout(() => {
        setCount((prevCount) =>
          Math.min(Math.floor(prevCount + increment), 100)
        );
        setTrigger(!trigger); // Déclenche la mise à jour suivante
      }, randomDelay);
    }
  }, [trigger, count]);

  return (
    <div id="preloader">
      <div className="container">
        <div className="content-square">
          <motion.div
            className="content-square-before"
            initial={{ width: "0%" }}
            animate={{ width: `${count}%` }}
          />
          <motion.div ref={scope} className="square" />
        </div>

        <motion.div
          className="content-count"
          variants={variantsCount}
          initial="normal"
          animate={loaderDone && "hidden"}
        >
          <motion.span className="count text-big" animate={controls}>
            {count}
          </motion.span>
          <span className="text-medium">%</span>
        </motion.div>
      </div>
    </div>
  );
};
