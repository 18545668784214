import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "@/App";
import "@/styles/index.scss";
import "@/i18n";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <BrowserRouter basename="/">
    <App />
  </BrowserRouter>
);
