import { Routes, Route } from "react-router-dom";
import { lazy } from "react";

const HomePage = lazy(() => import("@/screens/HomeScreen"));
const LegalPage = lazy(() => import("@/screens/LegalScreen"));
const PrivacyPage = lazy(() => import("@/screens/PrivacyScreen"));
const AboutUsPage = lazy(() => import("@/screens/AboutUsScreen"));
const ContactPage = lazy(() => import("@/screens/ContactScreen"));
const NotFoundPage = lazy(() => import("@/screens/NotFoundScreen"));

export const MyRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/legals" element={<LegalPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/aboutus" element={<AboutUsPage />} />
      <Route path="/contact" element={<ContactPage />} />

      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  );
};
