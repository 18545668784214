import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/translation.json";
import fr from "./locales/fr/translation.json";


const browserLang = navigator.language.split('-')[0]; // exp: "en" ou "fr"
const lng = browserLang === "fr" ? "fr" : "en"; 
const savedLng = localStorage.getItem("lng") || lng;

const resources = {
  en: { translation: en },
  fr: { translation: fr },
};

i18n
  .use(initReactI18next)
  .init({
    lng: savedLng,
    fallbackLng: "fr",
    resources,
    interpolation:{
        escapeValue: false,
    },
    debug: false,
  });

export default i18n;
